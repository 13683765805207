import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../utils/initFirebase';
import { doc, getDoc, DocumentData } from 'firebase/firestore';
import FullScreenLoader from '../../components/FullScreenLoader';
import styles from './mobile.module.css';

const MobileNewsDetailPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { category, newsId } = useParams();
  const navigate = useNavigate();
  console.log('newsId', newsId, category);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<DocumentData | undefined>(undefined);

  const getProfile = async () => {
    setIsLoading(true);
    const name = newsId;
    const type = category;

    if (name) {
      try {
        const docRef = doc(db, `${type}`, name);
        const docSnap = await getDoc(docRef);
        docSnap?.data();
        if (docSnap?.data()) {
          setProfileData(docSnap?.data());
        } else {
          navigate(`/`);
        }
        setIsLoading(false);
      } catch (e) {
        navigate(`/`);
        setIsLoading(false);
      }
    } else {
      navigate(`/`);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('welcome to kfba news page');
    getProfile();
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="mobileDiv">
        <div className={`${styles.header} ${isScrolled ? styles.headerScrolled : ''}`}>
          <a href="/">
            <img className={styles.logo} alt="" src={'/about/mobile/logo-black-mo.svg'} />
          </a>
          <button className={styles.menuIcon} onClick={toggleMenu}>
            <img alt="" src={'/about/mobile/icon-menu-24px.svg'} />
          </button>
        </div>
        <div className={`${styles.headerBg} ${isMenuOpen ? styles.active : ''}`}>
          <button className={styles.menuIcon} onClick={toggleMenu}>
            <img alt="" src={'/about/mobile/icon-menu-24px2.svg'} />
          </button>
          <div className={styles.menu}>
            <a href="/">
              <b className={styles.text}>Home</b>
            </a>
            <a href="/pages/about">
              <b className={styles.text}>About us</b>
            </a>
            <a href="/pages/profiles">
              <b className={styles.text}>Members</b>
            </a>
            <a href="/pages/news">
              <b className={styles.text}>News/notice</b>
            </a>
          </div>
        </div>

        <div className={styles.container}>
          {isLoading ? (
            <FullScreenLoader />
          ) : (
            <>
              <div className={styles.newsWrapper}>
                <div className={styles.newsTitle}>{profileData?.title}</div>
                <div className={styles.newsSubTitle}>{profileData?.subTitle}</div>
                <div className={styles.newsDate}>{profileData?.date}</div>
                <div className={styles.newsText} dangerouslySetInnerHTML={{ __html: profileData?.text }} />
                <div className={styles.newsList}>
                  <a href="/pages/news">목록으로 돌아가기</a>
                </div>
              </div>
            </>
          )}
        </div>

        <div className={styles.footer}>
          <a href="/">
            <img className={styles.logo} alt="" src={'/about/mobile/logo-gray.svg'} />
          </a>
          <div className={styles.menu}>
            <a href="/">
              <b className={styles.text}>Home</b>
            </a>
            <a href="/pages/about">
              <b className={styles.text}>About us</b>
            </a>
            <a href="/pages/profiles">
              <b className={styles.text}>Members</b>
            </a>
            <a href="/pages/news">
              <b className={styles.text}>News/notice</b>
            </a>
          </div>
        </div>
        <div className={styles.copy}>
          <div className={styles.copyText}>
            <div>개인정보처리방침</div>
            <div>이용약관</div>
          </div>
          <div className={styles.copyright}>
            Copyright © 2013 by <b>KFBA</b> All right reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNewsDetailPage;
