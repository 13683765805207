import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../utils/initFirebase';
import { doc, getDoc, DocumentData } from 'firebase/firestore';
import FullScreenLoader from '../../components/FullScreenLoader';
import styles from './news.module.css';
import MIndex from './mIndex';

const NewsDetailPage = () => {
  const { category, newsId } = useParams();
  const navigate = useNavigate();
  console.log('newsId', newsId, category);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<DocumentData | undefined>(undefined);

  const getProfile = async () => {
    setIsLoading(true);
    const name = newsId;
    const type = category;

    if (name) {
      try {
        const docRef = doc(db, `${type}`, name);
        const docSnap = await getDoc(docRef);
        docSnap?.data();
        if (docSnap?.data()) {
          setProfileData(docSnap?.data());
        } else {
          navigate(`/`);
        }
        setIsLoading(false);
      } catch (e) {
        navigate(`/`);
        setIsLoading(false);
      }
    } else {
      navigate(`/`);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('welcome to kfba news page');
    getProfile();
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="webDiv">
        <div className={styles.pcHeaderContainer}>
          <div className={`${styles.pcHeaderContainer} ${isScrolled ? styles.pcHeaderScrolled : ''}`}>
            <div className={styles.pcHeader}>
              <div className={`${styles.gnbWhite} `}>
                <a href="/">
                  <img
                    className={styles.logoWhiteIcon}
                    alt=""
                    src={`${isScrolled ? '/home/logoblack.svg' : '/home/logoblack.svg'}`}
                  />
                </a>
                <div className={styles.menu}>
                  <a href="/">
                    <b className={styles.home}>Home</b>
                  </a>
                  <a href="/pages/about">
                    <b className={styles.home}>About us</b>
                  </a>
                  <a href="/pages/profiles">
                    <b className={styles.home}>Members</b>
                  </a>
                  <a href="/pages/news">
                    <b className={styles.home}>News/notice</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.pcMembersContainer}>
          <div className={styles.pcMembers}>
            {isLoading ? (
              <FullScreenLoader />
            ) : (
              <>
                <div className={styles.newsWrapper}>
                  <div className={styles.newsTitle}>{profileData?.title}</div>
                  <div className={styles.newsSubTitle}>{profileData?.subTitle}</div>
                  <div className={styles.newsDate}>{profileData?.date}</div>
                  <div className={styles.newsText} dangerouslySetInnerHTML={{ __html: profileData?.text }} />
                  <div className={styles.newsList}>
                    <a href="/pages/news">목록으로 돌아가기</a>
                  </div>
                </div>

                <div className={styles.footer}>
                  <div className={styles.menu}>
                    <a href="/">
                      <img className={styles.kfbaIcon} alt="" src="/profileList/kfba.svg" />
                    </a>
                    <div className={styles.menu1}>
                      <a href="/">
                        <div className={styles.aboutUs}>HOME</div>
                      </a>
                      <a href="/pages/about">
                        <div className={styles.aboutUs}>About US</div>
                      </a>
                      <a href="/pages/profiles">
                        <div className={styles.aboutUs}>Members</div>
                      </a>
                      <a href="/pages/news">
                        <div className={styles.aboutUs}>NEWS/NOTICE</div>
                      </a>
                    </div>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.copyright2013Container}>
                      <span>{`Copyright © 2013 by `}</span>
                      <b className={styles.kfba}>KFBA</b>
                      <span> All right reserved.</span>
                    </div>
                    <div className={styles.buttons}>
                      <div className={styles.div12}>개인정보처리방침</div>
                      <div className={styles.div12}>이용약관</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* background */}
        {/* <div className={styles.footerBg}></div> */}
      </div>
      <MIndex />
    </>
  );
};

export default NewsDetailPage;
