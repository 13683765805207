import styled from 'styled-components';
import { Layout, Space, Spin } from 'antd';

const { Content } = Layout;

const FullScreenStyle = styled(Layout)`
  width: 100%;
  background-color: transparent;
`;

const ContentStyle = styled(Content)`
  margin: 0 auto;
  display: flex;
`;

const ScreenLoader = (height: any) => {
  return (
    <FullScreenStyle style={{ height: height?.height }}>
      <ContentStyle>
        <Space>
          <Spin size="large" />
        </Space>
      </ContentStyle>
    </FullScreenStyle>
  );
};

export default ScreenLoader;
