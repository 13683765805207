import React, { useState, useEffect } from 'react';
import styles from './about.module.css';
import { motion } from 'framer-motion';
import MIndex from './mIndex';

const AboutPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  console.log('welcome to kfba about page');

  return (
    <>
      <div className="webDiv">
        <div className={styles.pcHeaderContainer}>
          <div className={`${styles.pcHeaderContainer} ${isScrolled ? styles.pcHeaderScrolled : ''}`}>
            <div className={styles.pcHeader}>
              <div className={`${styles.gnbWhite} `}>
                <a href="/">
                  <img
                    className={styles.logoWhiteIcon}
                    alt=""
                    src={`${isScrolled ? '/home/logoblack.svg' : '/home/logoblack.svg'}`}
                  />
                </a>
                <div className={styles.menu}>
                  <a href="/">
                    <b className={styles.home}>Home</b>
                  </a>
                  <a href="/pages/about">
                    <b className={styles.home}>About us</b>
                  </a>
                  <a href="/pages/profiles">
                    <b className={styles.home}>Members</b>
                  </a>
                  <a href="/pages/news">
                    <b className={styles.home}>News/notice</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.pcAboutUsContainer}>
          <div className={styles.pcAboutUs}>
            <div className={styles.aboutContents}>
              <div className={styles.contents}>
                <div className={styles.div}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: 'easeOut' }}
                  >
                    “안녕하십니까!”
                  </motion.div>
                </div>
                <div className={styles.kfba10Container}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: 'easeOut', delay: 0.4 }}
                  >
                    <p className={styles.kfba}>2013년 작은 모임으로 시작된 한국방송진행자연합(KFBA)은</p>
                    <p
                      className={styles.kfba}
                    >{`지난 10년간 국내 최대, 국내 유일의 현업에서 활동중인 프리랜서 방송진행자들의 온오프라인 커뮤니티로 성장하여 자리잡았습니다. `}</p>
                  </motion.div>
                  <p className={styles.kfba}>&nbsp;</p>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: 'easeOut', delay: 0.8 }}
                  >
                    <p className={styles.kfba}>
                      현재 KFBA 안에는 아나운서, 쇼호스트, 행사MC, 외국어MC, 인플루언서등 다양한 분야에서 활동하는
                      방송진행자 300여명이 함께 활동하고 있습니다.
                    </p>
                  </motion.div>
                  <p className={styles.kfba}>&nbsp;</p>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: 'easeOut', delay: 1.2 }}
                  >
                    <p className={styles.kfba}>
                      KFBA는 방송진행자들의 권익과 복지를 위하여 계속해서 최선을 다할것이며 또한 실력있는 방송진행자들이
                      더욱 활발히 활동할수 있도록 후원해 나갈것입니다! 방송과 행사 현장에서 여러분과 만나뵙겠습니다
                    </p>
                  </motion.div>
                </div>
                <div className={styles.title}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: 'easeOut', delay: 1.6 }}
                  >
                    <div className={styles.div1}>한국 방송진행자 연합회장</div>
                    <div className={styles.div2}>이기상</div>
                  </motion.div>
                </div>
              </div>
              <div className={styles.imgAboutUs}>
                <div className={styles.associationPresident}>Association President</div>
                <img className={styles.imgAboutUsIcon} alt="" src="/about/imgaboutus@2x.png" />
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.menu1}>
                <img className={styles.kfbaIcon} alt="" src="/about/kfba.svg" />
                <div className={styles.menu2}>
                  <a href="/">
                    <div className={styles.history}>HOME</div>
                  </a>
                  <a href="/pages/about">
                    <div className={styles.history}>About US</div>
                  </a>
                  <a href="/pages/profiles">
                    <div className={styles.history}>Members</div>
                  </a>
                  <a href="/pages/news">
                    <div className={styles.history}>NEWS/NOTICE</div>
                  </a>
                </div>
              </div>
              <div className={styles.info}>
                <div className={styles.copyright2013Container}>
                  <span>{`Copyright © 2013 by `}</span>
                  <b className={styles.kfba3}>KFBA</b>
                  <span> All right reserved.</span>
                </div>
                <div className={styles.buttons}>
                  <div className={styles.div3}>개인정보처리방침</div>
                  <div className={styles.div3}>이용약관</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* backgroud */}
        {/* <div className={styles.footerBg}></div> */}
      </div>
      <MIndex />
    </>
  );
};

export default AboutPage;
