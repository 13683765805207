import React, { useState, useEffect, useRef } from 'react';
import styles from './mobile.module.css';
import { motion } from 'framer-motion';

import { doc, collection, getDocs, setDoc } from 'firebase/firestore';
import { db } from '../../utils/initFirebase';
import ScreenLoader from '../../components/ScreenLoader';

import { notification } from 'antd';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

interface DataType {
  isApp: boolean;
  id: string;
  broadcastingCareer: string[];
  etcCareer: string[];
  eventCareer: string[];
  facebookLink: string;
  youtubeLink: string;
  profileImage: string[];
  profileYoutube: string[];
  representProfileImage: Array<{ url: string }>;
  userEnName: string;
  userName: string;
  userUrl: string;
  category: string[];
}

const MobileHomePage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 60;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const contactRef = useRef<HTMLDivElement>(null);

  const [formName, setFormName] = useState('');
  const [formPhone, setFormPhone] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formContent, setFormContent] = useState('');
  const [formCheck, setFormCheck] = useState(false);
  const [shouldUpdateProfile, setShouldUpdateProfile] = useState<boolean>(false);
  const currentDate = new Date().toISOString().split('T')[0];
  const timestamp = Date.now();

  const contactId = `${currentDate}-${timestamp}`;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<{ [key: string]: boolean }>({});
  const [profileListData, setProfileListData] = useState<DataType[]>([]);

  const seededRandom = (seed: any) => {
    return function () {
      var x = Math.sin(seed++) * 10000;
      return x - Math.floor(x);
    };
  };

  const getSeedForToday = () => {
    const today = new Date();
    return today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate();
  };

  const getRandomItems = (array: any, seed: any, count: any) => {
    const random = seededRandom(seed);
    const result = [];
    const indexes = array.map((_: any, index: any) => index);

    for (let i = 0; i < count && indexes.length > 0; i++) {
      const randomIndex = Math.floor(random() * indexes.length);
      result.push(array[indexes[randomIndex]]);
      indexes.splice(randomIndex, 1); // 선택된 인덱스 제거
    }

    return result;
  };

  const getProfileList = async () => {
    setIsLoading(true);

    try {
      const collectionRef = collection(db, 'profile');
      const snapshot = await getDocs(collectionRef);

      const dataList = snapshot.docs.map((collectionDoc) => ({
        id: collectionDoc.id,
        ...collectionDoc.data(),
      })) as DataType[];

      const filteredList = dataList.filter((item) => item?.isApp === true);

      // 오늘의 시드를 사용하여 필터링된 리스트에서 4개 선택
      const seed = getSeedForToday();
      const selectedProfiles = getRandomItems(filteredList, seed, 4);

      setProfileListData(selectedProfiles);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: '프로필 리스트 데이터 오류',
        description: `Error getting cached document: ${e}`,
      });
    }
  };

  const setProfile = async () => {
    try {
      await setDoc(doc(db, 'contact', contactId), {
        formName: formName,
        formPhone: formPhone,
        formEmail: formEmail,
        formContent: formContent,
        formCheck: formCheck,
        isRead: false,
      });
      notification.success({
        message: 'Contact Success',
        description: '섭외 문의가 완료되었습니다.',
      });
      setFormName('');
      setFormPhone('');
      setFormEmail('');
      setFormContent('');
      setFormCheck(false);
    } catch (e) {
      notification.error({
        message: 'Contact failed',
        description: `섭외 문의 입력 폼을 다시 확인해 주세요.`,
      });
    }
  };

  const submitForm = async () => {
    if (formName === '') {
      alert('성함을 입력해주세요.');
      return;
    }
    if (formPhone === '') {
      alert('연락처를 입력해주세요.');
      return;
    }
    if (formEmail === '') {
      alert('이메일을 입력해주세요.');
      return;
    }
    if (formContent === '') {
      alert('섭외 내용을 입력해주세요.');
      return;
    }
    if (!formCheck) {
      alert('개인정보처리방침에 동의해주세요.');
      return;
    }
    setShouldUpdateProfile(true);
  };

  useEffect(() => {
    if (shouldUpdateProfile) {
      setProfile();
      setShouldUpdateProfile(false);
    }
  }, [shouldUpdateProfile]);

  useEffect(() => {
    console.log('welcome to kfba page');
    getProfileList();
  }, []);

  useEffect(() => {
    const initialLoadingState: { [key: string]: boolean } = {};
    profileListData.forEach((item) => {
      initialLoadingState[item.id] = true;
    });
    setImageLoading(initialLoadingState);
  }, [profileListData]);

  const handleImageLoad = (id: string) => {
    setImageLoading((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const intervalTime = 3000;
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const startTimeRef = useRef<number>(Date.now());

  const shuffleArray = (array: any[]): any[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const basicimages = [
    './home/banner/img-main-mo-01.png',
    './home/banner/img-main-mo-02.png',
    './home/banner/img-main-mo-03.png',
    './home/banner/img-main-mo-04.png',
    './home/banner/img-main-mo-05.png',
    './home/banner/img-main-mo-06.png',
    './home/banner/img-main-mo-07.png',
    './home/banner/img-main-mo-08.png',
    './home/banner/img-main-mo-09.png',
    './home/banner/img-main-mo-10.png',
  ];

  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const shuffledImages = shuffleArray(basicimages);
    setImages(shuffledImages);

    shuffledImages.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  useEffect(() => {
    if (images?.length === undefined) return;
    const updateProgress = () => {
      const elapsed = Date.now() - startTimeRef.current;
      const newProgress = Math.min(100, (elapsed / intervalTime) * 100);
      setProgress(newProgress);
    };

    const changeImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images?.length);
      startTimeRef.current = Date.now();
      setProgress(0);
    };

    if (!isPaused) {
      const remaining = intervalTime - ((Date.now() - startTimeRef.current) % intervalTime);
      timeoutRef.current = setTimeout(changeImage, remaining);

      const progressInterval = setInterval(updateProgress, 100);
      return () => {
        clearInterval(progressInterval);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      };
    } else {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    }
  }, [isPaused, currentImageIndex, images?.length]);

  const togglePause = () => {
    if (isPaused) {
      // 재개 시
      const remainingTime = (1 - progress / 100) * intervalTime;
      startTimeRef.current = Date.now() - (intervalTime - remainingTime);
      timeoutRef.current = setTimeout(() => {
        setCurrentImageIndex((prevIndex) => prevIndex % images?.length);
        setProgress(0);
        startTimeRef.current = Date.now();
      }, remainingTime);
    } else {
      // 일시 정지 시
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      const elapsed = Date.now() - startTimeRef.current;
      setProgress((elapsed / intervalTime) * 100);
    }
    setIsPaused(!isPaused);
  };

  const sectionBgImage = images?.[currentImageIndex];
  const sectionPage = `${(currentImageIndex + 1)?.toString()?.padStart(2, '0')}`;

  return (
    <>
      <div className="mobileDiv">
        <div className={`${styles.header} ${isScrolled ? styles.headerScrolled : ''}`}>
          <a href="/">
            <img
              className={styles.logo}
              alt=""
              src={`${isScrolled ? '/about/mobile/logo-black-mo.svg' : '/about/mobile/logo-white-mo.svg'}`}
            />
          </a>
          <button className={styles.menuIcon} onClick={toggleMenu}>
            <img
              alt=""
              src={`${isScrolled ? '/about/mobile/icon-menu-24px.svg' : '/about/mobile/icon-menu-24px-white.svg'}`}
            />
          </button>
        </div>
        <div className={`${styles.headerBg} ${isMenuOpen ? styles.active : ''}`}>
          <button className={styles.menuIcon} onClick={toggleMenu}>
            <img alt="" src={'/about/mobile/icon-menu-24px2.svg'} />
          </button>
          <div className={styles.menu}>
            <a href="/">
              <b className={styles.text}>Home</b>
            </a>
            <a href="/pages/about">
              <b className={styles.text}>About us</b>
            </a>
            <a href="/pages/profiles">
              <b className={styles.text}>Members</b>
            </a>
            <a href="/pages/news">
              <b className={styles.text}>News/notice</b>
            </a>
          </div>
        </div>

        <div className={styles.container}>
          <div className={styles.bgContainer}>
            <img
              className={styles.bg}
              src={sectionBgImage}
              // style={{ backgroundImage: `url(${sectionBgImage})` }}
            />
          </div>
          <div className={styles.animation}>
            <div className={styles.div1}>
              <span className={styles.div2}>{sectionPage}</span>
              <span> / {images?.length?.toString()?.padStart(2, '0')}</span>
            </div>
            <div className={styles.bgBg}>
              <div className={styles.bg1} style={{ width: `${progress}%` }} />
            </div>

            <div className={styles.btnWrap} style={{ cursor: 'pointer' }} onClick={togglePause}>
              {isPaused ? (
                <img className={styles.startBtn} alt="" src="/home/bg.svg" />
              ) : (
                <>
                  <div className={styles.bg2} />
                  <div className={styles.bg3} />
                </>
              )}
            </div>
          </div>

          <div className={styles.memberWrapper}>
            <a href="/pages/profiles" style={{ cursor: 'pointer' }}>
              <img className={styles.buttonPlus} alt="" src="/home/button--plus.svg" />
              <div className={styles.buttonPlusMore}>more</div>
            </a>
            <div className={styles.title}>오늘의 KFBA</div>
            <div className={styles.subTitle}>
              매일매일 <b>KFBA</b>의 프로페셔널 하고 능력있는
              <br />
              정회원들을 여러분께 제안해 드립니다.
            </div>
            {isLoading ? (
              <ScreenLoader height={580} />
            ) : (
              <div className={styles.memberList}>
                {profileListData.map((item, i) => (
                  <a href={`/${item?.userUrl}`} key={i}>
                    <div className={styles.memberBox}>
                      {imageLoading[item.id] && <ScreenLoader height={220} />}
                      <img
                        className={styles.memberImage}
                        alt=""
                        src={item?.representProfileImage?.[0]?.url}
                        onLoad={() => handleImageLoad(item.id)}
                        style={{ display: imageLoading[item.id] ? 'none' : 'block' }}
                      />
                      <div className={styles.memberTitle}>아나운서</div>
                      <div className={styles.memberName}>{item?.userName}</div>
                      <div className={styles.memberEnName}>{item?.userEnName}</div>
                    </div>
                  </a>
                ))}
              </div>
            )}
          </div>

          <div className={styles.imgProfiles}>
            <img className={styles.thumbAhnSooMinIcon} alt="" src="/home/thumbahnsoomin@2x.png" />
            <img className={styles.thumbAnDaYunIcon} alt="" src="/home/thumbandayun@2x.png" />
            <img className={styles.thumbBaekIlHongIcon} alt="" src="/home/thumbbaekilhong@2x.png" />
            <img className={styles.thumbBaekSuJinIcon} alt="" src="/home/thumbbaeksujin@2x.png" />
            <img className={styles.thumbBaekSunHyeIcon} alt="" src="/home/thumbbaeksunhye@2x.png" />
            <img className={styles.thumbBrittyNaKyungKoIcon} alt="" src="/home/thumbbrittynakyungko@2x.png" />
            <img className={styles.thumbChaSeungIlIcon} alt="" src="/home/thumbchaseungil@2x.png" />
            <img className={styles.thumbChaYuJinIcon} alt="" src="/home/thumbchayujin@2x.png" />
            <img className={styles.thumbChangChaeRinIcon} alt="" src="/home/thumbchangchaerin@2x.png" />
            <img className={styles.thumbChoAmyIcon} alt="" src="/home/thumbchoamy@2x.png" />
            <img className={styles.thumbChoiInKyungIcon} alt="" src="/home/thumbchoiinkyung@2x.png" />
            <img className={styles.thumbChoiSulKi2Icon} alt="" src="/home/thumbchoisulki2@2x.png" />
            <img className={styles.thumbChoiSulKiIcon} alt="" src="/home/thumbchoisulki@2x.png" />
            <img className={styles.thumbChoiYeJinIcon} alt="" src="/home/thumbchoiyejin@2x.png" />
            <img className={styles.thumbKwonYuRiIcon} alt="" src="/home/thumbkwonyuri@2x.png" />
            <img className={styles.thumbLeeBoRamIcon} alt="" src="/home/thumbleeboram@2x.png" />
            <img className={styles.thumbLeeClaudiaIcon} alt="" src="/home/thumbleeclaudia@2x.png" />
            <img className={styles.thumbLeeDaInIcon} alt="" src="/home/thumbleedain@2x.png" />
            <img className={styles.thumbLeeDoKyungIcon} alt="" src="/home/thumbleedokyung@2x.png" />
            <img className={styles.thumbLeeHaRimIcon} alt="" src="/home/thumbleeharim@2x.png" />
            <img className={styles.thumbHanJiHeeIcon} alt="" src="/home/thumbhanjihee@2x.png" />
            <img className={styles.thumbHanJuEunIcon} alt="" src="/home/thumbhanjueun@2x.png" />
            <img className={styles.thumbHongKyeongHwaIcon} alt="" src="/home/thumbhongkyeonghwa@2x.png" />
            <img className={styles.thumbHongTaeGyunIcon} alt="" src="/home/thumbhongtaegyun@2x.png" />
            <img className={styles.thumbHongYooIcon} alt="" src="/home/thumbhongyoo@2x.png" />
            <img className={styles.thumbHwangBoRaIcon} alt="" src="/home/thumbhwangbora@2x.png" />
            <img className={styles.thumbHwangInSungIcon} alt="" src="/home/thumbhwanginsung@2x.png" />
            <img className={styles.thumbHwangNuRiIcon} alt="" src="/home/thumbhwangnuri@2x.png" />
            <img className={styles.thumbHwangYeRinIcon} alt="" src="/home/thumbhwangyerin@2x.png" />
            <img className={styles.thumbImHyunJungIcon} alt="" src="/home/thumbimhyunjung@2x.png" />
            <img className={styles.thumbJangEuGeneIcon} alt="" src="/home/thumbjangeugene@2x.png" />
            <img className={styles.thumbLeeJinJooIcon} alt="" src="/home/thumbleejinjoo@2x.png" />
            <img className={styles.thumbLeeJuneIcon} alt="" src="/home/thumbleejune@2x.png" />
            <img className={styles.thumbLeeKaEunIcon} alt="" src="/home/thumbleekaeun@2x.png" />
            <img className={styles.thumbLeeKangJunIcon} alt="" src="/home/thumbleekangjun@2x.png" />
            <img className={styles.thumbLeeKiSangIcon} alt="" src="/home/thumbleekisang@2x.png" />
            <img className={styles.thumbLeeKyoungYounIcon} alt="" src="/home/thumbleekyoungyoun@2x.png" />
            <img className={styles.thumbLeeMinYoungIcon} alt="" src="/home/thumbleeminyoung@2x.png" />
            <img className={styles.thumbLeeNaRaeIcon} alt="" src="/home/thumbleenarae@2x.png" />
            <img className={styles.thumbLeeRoEunIcon} alt="" src="/home/thumbleeroeun@2x.png" />
            <img className={styles.thumbJeonSeungMinIcon} alt="" src="/home/thumbjeonseungmin@2x.png" />
            <img className={styles.thumbJeongJaeKyungIcon} alt="" src="/home/thumbjeongjaekyung@2x.png" />
            <img className={styles.thumbJeongSoHaIcon} alt="" src="/home/thumbjeongsoha@2x.png" />
            <img className={styles.thumbJessicaJiYoungParkIcon} alt="" src="/home/thumbjessicajiyoungpark@2x.png" />
            <img className={styles.thumbJoAhRamIcon} alt="" src="/home/thumbjoahram@2x.png" />
            <img className={styles.thumbJoHaNeulIcon} alt="" src="/home/thumbjohaneul@2x.png" />
            <img className={styles.thumbJungAeRanIcon} alt="" src="/home/thumbjungaeran@2x.png" />
            <img className={styles.thumbJungDaYoungIcon} alt="" src="/home/thumbjungdayoung@2x.png" />
            <img className={styles.thumbLeeSoYeonIcon} alt="" src="/home/thumbleesoyeon@2x.png" />
            <img className={styles.thumbLeeSooBinIcon} alt="" src="/home/thumbleesoobin@2x.png" />
            <img className={styles.thumbLeeYongSeokIcon} alt="" src="/home/thumbleeyongseok@2x.png" />
            <img className={styles.thumbLeeYuGyeongIcon} alt="" src="/home/thumbleeyugyeong@2x.png" />
            <img className={styles.thumbLeeYunJiIcon} alt="" src="/home/thumbleeyunji@2x.png" />
            <img className={styles.thumbMinKyoungSuIcon} alt="" src="/home/thumbminkyoungsu@2x.png" />
            <img className={styles.thumbMisorangNatashaSeoIcon} alt="" src="/home/thumbmisorangnatashaseo@2x.png" />
            <img className={styles.thumbMoonJeongEunIcon} alt="" src="/home/thumbmoonjeongeun@2x.png" />
            <img className={styles.thumbNohJiSunIcon} alt="" src="/home/thumbnohjisun@2x.png" />
            <img className={styles.thumbNohSeongMinIcon} alt="" src="/home/thumbnohseongmin@2x.png" />
            <img className={styles.thumbOhSuHwaIcon} alt="" src="/home/thumbohsuhwa@2x.png" />
            <img className={styles.thumbOhYouJinIcon} alt="" src="/home/thumbohyoujin@2x.png" />
            <img className={styles.thumbJungYouHyunIcon} alt="" src="/home/thumbjungyouhyun@2x.png" />
            <img className={styles.thumbKangDoHyunIcon} alt="" src="/home/thumbkangdohyun@2x.png" />
            <img className={styles.thumbKangHyunYiIcon} alt="" src="/home/thumbkanghyunyi@2x.png" />
            <img className={styles.thumbKangMinChaeIcon} alt="" src="/home/thumbkangminchae@2x.png" />
            <img className={styles.thumbKimAhHyunIcon} alt="" src="/home/thumbkimahhyun@2x.png" />
            <img className={styles.thumbParkJinHyunIcon} alt="" src="/home/thumbparkjinhyun@2x.png" />
            <img className={styles.thumbParkJunHuiIcon} alt="" src="/home/thumbparkjunhui@2x.png" />
            <img className={styles.thumbParkKeunAhIcon} alt="" src="/home/thumbparkkeunah@2x.png" />
            <img className={styles.thumbParkSaRaIcon} alt="" src="/home/thumbparksara@2x.png" />
            <img className={styles.thumbParkSaeHaIcon} alt="" src="/home/thumbparksaeha@2x.png" />
            <img className={styles.thumbParkSeInIcon} alt="" src="/home/thumbparksein@2x.png" />
            <img className={styles.thumbParkSeoJinIcon} alt="" src="/home/thumbparkseojin@2x.png" />
            <img className={styles.thumbParkSoYeonIcon} alt="" src="/home/thumbparksoyeon@2x.png" />
            <img className={styles.thumbParkYuRimIcon} alt="" src="/home/thumbparkyurim@2x.png" />
            <img className={styles.thumbRhoSeungYoungIcon} alt="" src="/home/thumbrhoseungyoung@2x.png" />
            <img className={styles.thumbRohKyungMiIcon} alt="" src="/home/thumbrohkyungmi@2x.png" />
            <img className={styles.thumbSaEunHyeIcon} alt="" src="/home/thumbsaeunhye@2x.png" />
            <img className={styles.thumbSeoEunKyungIcon} alt="" src="/home/thumbseoeunkyung@2x.png" />
            <img className={styles.thumbSeolYeImIcon} alt="" src="/home/thumbseolyeim@2x.png" />
            <img className={styles.thumbKimAryeonIcon} alt="" src="/home/thumbkimaryeon@2x.png" />
            <img className={styles.thumbKimJiHuynIcon} alt="" src="/home/thumbkimjihuyn@2x.png" />
            <img className={styles.thumbKimJinKyongIcon} alt="" src="/home/thumbkimjinkyong@2x.png" />
            <img className={styles.thumbShimJiMinIcon} alt="" src="/home/thumbshimjimin@2x.png" />
            <img className={styles.thumbShinGyungMinIcon} alt="" src="/home/thumbshingyungmin@2x.png" />
            <img className={styles.thumbShinMiJeongIcon} alt="" src="/home/thumbshinmijeong@2x.png" />
            <img className={styles.thumbShinSoYoungIcon} alt="" src="/home/thumbshinsoyoung@2x.png" />
            <img className={styles.thumbShonBoRyunIcon} alt="" src="/home/thumbshonboryun@2x.png" />
            <img className={styles.thumbSinDoHwiIcon} alt="" src="/home/thumbsindohwi@2x.png" />
            <img className={styles.thumbSonHyeSeongIcon} alt="" src="/home/thumbsonhyeseong@2x.png" />
            <img className={styles.thumbSongHaNaIcon} alt="" src="/home/thumbsonghana@2x.png" />
            <img className={styles.thumbSongHyeJiIcon} alt="" src="/home/thumbsonghyeji@2x.png" />
            <img className={styles.thumbSongJiEunIcon} alt="" src="/home/thumbsongjieun@2x.png" />
            <img className={styles.thumbSongJiYoungIcon} alt="" src="/home/thumbsongjiyoung@2x.png" />
            <img className={styles.thumbSulSuHyunIcon} alt="" src="/home/thumbsulsuhyun@2x.png" />
            <img className={styles.thumbSungKiYounIcon} alt="" src="/home/thumbsungkiyoun@2x.png" />
            <img className={styles.thumbWonLeeSeulIcon} alt="" src="/home/thumbwonleeseul@2x.png" />
            <img className={styles.thumbKimJinYeongIcon} alt="" src="/home/thumbkimjinyeong@2x.png" />
            <img className={styles.thumbKimJinIcon} alt="" src="/home/thumbkimjin@2x.png" />
            <img className={styles.thumbKimJooHeeIcon} alt="" src="/home/thumbkimjoohee@2x.png" />
            <img className={styles.thumbKimMiJooIcon} alt="" src="/home/thumbkimmijoo@2x.png" />
            <img className={styles.thumbYangYunSeoIcon} alt="" src="/home/thumbyangyunseo@2x.png" />
            <img className={styles.thumbYeonSunJaneYuIcon} alt="" src="/home/thumbyeonsunjaneyu@2x.png" />
            <img className={styles.thumbYooMiRaIcon} alt="" src="/home/thumbyoomira@2x.png" />
            <img className={styles.thumbYooSeulJiIcon} alt="" src="/home/thumbyooseulji@2x.png" />
            <img className={styles.thumbYouSuMinIcon} alt="" src="/home/thumbyousumin@2x.png" />
            <img className={styles.thumbYuSeJinIcon} alt="" src="/home/thumbyusejin@2x.png" />
            <img className={styles.thumbYunJiUnIcon} alt="" src="/home/thumbyunjiun@2x.png" />
            <img className={styles.thumbYunJinEIcon} alt="" src="/home/thumbyunjine@2x.png" />
            <img className={styles.thumbYunMinHyeokIcon} alt="" src="/home/thumbyunminhyeok@2x.png" />
            <img className={styles.thumbKimSeoHeeIcon} alt="" src="/home/thumbkimseohee@2x.png" />
            <img className={styles.thumbKimSeoYoungIcon} alt="" src="/home/thumbkimseoyoung@2x.png" />
            <img className={styles.thumbKimSoRaIcon} alt="" src="/home/thumbkimsora@2x.png" />
            <img className={styles.thumbKimSoYoungKellyIcon} alt="" src="/home/thumbkimsoyoungkelly@2x.png" />
            <img className={styles.thumbKimSuJungIcon} alt="" src="/home/thumbkimsujung@2x.png" />
            <img className={styles.thumbKimSuZyIcon} alt="" src="/home/thumbkimsuzy@2x.png" />
            <img className={styles.thumbKimWooJoongIcon} alt="" src="/home/thumbkimwoojoong@2x.png" />
            <img className={styles.thumbKimYooJeongIcon} alt="" src="/home/thumbkimyoojeong@2x.png" />
            <img className={styles.thumbKoYuKyeongIcon} alt="" src="/home/thumbkoyukyeong@2x.png" />
            <img className={styles.thumbKongHeeYeonIcon} alt="" src="/home/thumbkongheeyeon@2x.png" />
            <img className={styles.thumbKongInHyeIcon} alt="" src="/home/thumbkonginhye@2x.png" />
            <img className={styles.thumbJeonLeeSeulIcon} alt="" src="/home/thumbjeonleeseul@2x.png" />
            <img className={styles.thumbJungSiYoonIcon} alt="" src="/home/thumbjungsiyoon@2x.png" />
            <img className={styles.thumbJungYoonAIcon} alt="" src="/home/thumbjungyoona@2x.png" />
            <img className={styles.thumbJungYoonJiIcon} alt="" src="/home/thumbjungyoonji@2x.png" />
            <img className={styles.thumbKimHyoJuIcon} alt="" src="/home/thumbkimhyoju@2x.png" />
            <img className={styles.thumbKimHyoMiIcon} alt="" src="/home/thumbkimhyomi@2x.png" />
            <img className={styles.thumbKimHyunAhIcon} alt="" src="/home/thumbkimhyunah@2x.png" />
            <img className={styles.thumbKimSeHeeIcon} alt="" src="/home/thumbkimsehee@2x.png" />
            <img className={styles.thumbKimSeYoungIcon} alt="" src="/home/thumbkimseyoung@2x.png" />
            <img className={styles.thumbYangseonghyunIcon} alt="" src="/home/thumbyangseonghyun@2x.png" />
            <img className={styles.thumbLeeJiYoungIcon} alt="" src="/home/thumbleejiyoung@2x.png" />
            <img className={styles.thumbJangWuJuIcon} alt="" src="/home/thumbjangwuju@2x.png" />
            <img className={styles.thumbJungMuRinIcon} alt="" src="/home/thumbjungmurin@2x.png" />
            <img className={styles.thumbKimHyeYeonIcon} alt="" src="/home/thumbkimhyeyeon@2x.png" />
            <img className={styles.thumbKimRaylaIcon} alt="" src="/home/thumbkimrayla@2x.png" />
            <img className={styles.thumbJeonEunJuIcon} alt="" src="/home/thumbjeoneunju@2x.png" />
            <img className={styles.thumbLeeJiYoenIcon} alt="" src="/home/thumbleejiyoen@2x.png" />
            <img className={styles.thumbJangHanAIcon} alt="" src="/home/thumbjanghana@2x.png" />
            <img className={styles.thumbJungMinHyangIcon} alt="" src="/home/thumbjungminhyang@2x.png" />
            <img className={styles.thumbKimHueJuIcon} alt="" src="/home/thumbkimhueju@2x.png" />
          </div>
          <div className={styles.profileTitleBg} />
          <div className={styles.profileTitle}>
            국내 유일, 국내 최대
            <br />
            프리랜서 방송진행자 커뮤니티
            <br />
            KFBA에서
            <br />
            여러분이 찾는 방송 진행자를
            <br />
            만나보세요!
          </div>

          <div className={styles.contactWrapper} ref={contactRef}>
            <div className={styles.subTitle}>
              <b>010.3210.9345</b>로 전화 혹은 섭외문의
              <br />
              주시면 친절하게 답변 해 드리겠습니다.
            </div>
            <div className={styles.title}>
              CONTACT
              <br />
              US
            </div>
          </div>

          <div className={styles.contactForm} id="contactMobile">
            <div className={styles.placeholder}>
              <FloatingLabel controlId="form-name" label="성함">
                <Form.Control value={formName} placeholder="" onChange={(e) => setFormName(e.target.value)} />
              </FloatingLabel>
            </div>
            <div className={styles.placeholder}>
              <FloatingLabel controlId="form-phone" label="연락처">
                <Form.Control value={formPhone} placeholder="" onChange={(e) => setFormPhone(e.target.value)} />
              </FloatingLabel>
            </div>
            <div className={styles.placeholder}>
              <FloatingLabel controlId="form-email" label="이메일 주소">
                <Form.Control
                  value={formEmail}
                  type="email"
                  placeholder=""
                  onChange={(e) => setFormEmail(e.target.value)}
                />
              </FloatingLabel>
            </div>
            <div className={styles.placeholder}>
              <FloatingLabel controlId="form-content" label="섭외 내용">
                <Form.Control
                  value={formContent}
                  as="textarea"
                  placeholder=""
                  style={{ height: '200px' }}
                  onChange={(e) => setFormContent(e.target.value)}
                />
              </FloatingLabel>
            </div>

            <div className={styles.checkInfo}>
              <div className={styles.checkboxDefault}>
                <Form.Check
                  type={'checkbox'}
                  id={'form-checkbox'}
                  checked={formCheck}
                  onChange={(e) => setFormCheck(e.target.checked)}
                />
              </div>
              <div className={styles.checkText}>
                <span className={styles.under}>개인정보처리방침</span>
                <span className={styles.underText}>에 동의합니다.</span>
              </div>
            </div>

            <div className={styles.formBtn} onClick={() => submitForm()}>
              <div>섭외 문의하기</div>
              <img alt="" src={'/home/mobile/ico-chevron-right-20px.svg'} />
            </div>
            <div className={styles.formBtnFooter}></div>
          </div>
        </div>

        <div className={styles.footer}>
          <a href="/">
            <img className={styles.logo} alt="" src={'/about/mobile/logo-gray.svg'} />
          </a>
          <div className={styles.menu}>
            <a href="/">
              <b className={styles.text}>Home</b>
            </a>
            <a href="/pages/about">
              <b className={styles.text}>About us</b>
            </a>
            <a href="/pages/profiles">
              <b className={styles.text}>Members</b>
            </a>
            <a href="/pages/news">
              <b className={styles.text}>News/notice</b>
            </a>
          </div>
        </div>
        <div className={styles.copy}>
          <div className={styles.copyText}>
            <div>개인정보처리방침</div>
            <div>이용약관</div>
          </div>
          <div className={styles.copyright}>
            Copyright © 2013 by <b>KFBA</b> All right reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileHomePage;
