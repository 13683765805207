import { Route, Routes } from 'react-router-dom';

// Pages
import HomePage from './pages/home';
import AboutPage from './pages/about';
import ProfileListPage from './pages/profileList';
import ProfilePage from './pages/profile';
import NewsPage from './pages/news';
import NewsDetailPage from './pages/newsDetail';

function App() {
  return (
    <>
      <Routes>
        {/* Public Route */}
        <Route path="/" element={<HomePage />} />
        <Route path="/pages/about" element={<AboutPage />} />
        <Route path="/pages/profiles" element={<ProfileListPage />} />
        <Route path="/:userId" element={<ProfilePage />} />
        <Route path="/pages/news" element={<NewsPage />} />
        <Route path="/pages/:category/:newsId" element={<NewsDetailPage />} />
      </Routes>
    </>
  );
}

export default App;
