import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper as SwiperCore } from 'swiper/types';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../utils/initFirebase';
import { doc, getDoc, DocumentData, setDoc } from 'firebase/firestore';
import FullScreenLoader from '../../components/FullScreenLoader';
import styles from './mobile.module.css';
import { notification } from 'antd';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

const MobileProfilePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { userId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<DocumentData | undefined>(undefined);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
  const swiperRef = useRef<SwiperCore>();
  const videoSwiperRef = useRef<SwiperCore>();

  const getProfile = async () => {
    setIsLoading(true);
    const name = userId;

    if (name) {
      try {
        const docRef = doc(db, 'profile', name);
        const docSnap = await getDoc(docRef);
        docSnap?.data();
        if (docSnap?.data()) {
          setProfileData(docSnap?.data());
        } else {
          navigate(`/`);
        }
        setIsLoading(false);
      } catch (e) {
        navigate(`/`);
        setIsLoading(false);
      }
    } else {
      navigate(`/`);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('welcome to kfba profile page');
    getProfile();
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onSlideChange = (swiper: SwiperCore) => {
    setCurrentIndex(swiper.realIndex);
  };

  const goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const onVideoSlideChange = (swiper: SwiperCore) => {
    console.log('currentVideoIndex', currentVideoIndex);
    setCurrentVideoIndex(swiper.realIndex);
  };

  const goToPrevVideoSlide = () => {
    if (videoSwiperRef.current) {
      videoSwiperRef.current.slidePrev();
    }
  };

  const goToNextVideoSlide = () => {
    if (videoSwiperRef.current) {
      videoSwiperRef.current.slideNext();
    }
  };

  const [formName, setFormName] = useState('');
  const [formPhone, setFormPhone] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formContent, setFormContent] = useState('');
  const [formCheck, setFormCheck] = useState(false);
  const [shouldUpdateProfile, setShouldUpdateProfile] = useState<boolean>(false);
  const currentDate = new Date().toISOString().split('T')[0];
  const timestamp = Date.now();

  const contactId = `${currentDate}-${timestamp}`;

  const setProfile = async () => {
    try {
      await setDoc(doc(db, 'contact', contactId), {
        formName: formName,
        formPhone: formPhone,
        formEmail: formEmail,
        formContent: formContent,
        formCheck: formCheck,
        isRead: false,
      });
      notification.success({
        message: 'Contact Success',
        description: '섭외 문의가 완료되었습니다.',
      });
      setFormName('');
      setFormPhone('');
      setFormEmail('');
      setFormContent('');
      setFormCheck(false);
    } catch (e) {
      notification.error({
        message: 'Contact failed',
        description: `섭외 문의 입력 폼을 다시 확인해 주세요.`,
      });
    }
  };

  const submitForm = async () => {
    if (formName === '') {
      alert('성함을 입력해주세요.');
      return;
    }
    if (formPhone === '') {
      alert('연락처를 입력해주세요.');
      return;
    }
    if (formEmail === '') {
      alert('이메일을 입력해주세요.');
      return;
    }
    if (formContent === '') {
      alert('섭외 내용을 입력해주세요.');
      return;
    }
    if (!formCheck) {
      alert('개인정보처리방침에 동의해주세요.');
      return;
    }
    setShouldUpdateProfile(true);
  };

  useEffect(() => {
    if (shouldUpdateProfile) {
      setProfile();
      setShouldUpdateProfile(false);
    }
  }, [shouldUpdateProfile]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(currentIndex);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (videoSwiperRef.current) {
      videoSwiperRef.current.slideTo(currentVideoIndex);
    }
  }, [currentVideoIndex]);

  return (
    <>
      <div className="mobileDiv">
        <div className={`${styles.header} ${styles.headerScrolled}`}>
          <a href="/">
            <img className={styles.logo} alt="" src={'/about/mobile/logo-black-mo.svg'} />
          </a>
          <button className={styles.menuIcon} onClick={toggleMenu}>
            <img alt="" src={'/about/mobile/icon-menu-24px.svg'} />
          </button>
        </div>
        <div className={`${styles.headerBg} ${isMenuOpen ? styles.active : ''}`}>
          <button className={styles.menuIcon} onClick={toggleMenu}>
            <img alt="" src={'/about/mobile/icon-menu-24px2.svg'} />
          </button>
          <div className={styles.menu}>
            <a href="/">
              <b className={styles.text}>Home</b>
            </a>
            <a href="/pages/about">
              <b className={styles.text}>About us</b>
            </a>
            <a href="/pages/profiles">
              <b className={styles.text}>Members</b>
            </a>
            <a href="/pages/news">
              <b className={styles.text}>News/notice</b>
            </a>
          </div>
        </div>

        <div className={styles.container}>
          {isLoading ? (
            <FullScreenLoader />
          ) : (
            <>
              <div className={styles.bgWrrapper} />
              <div className={styles.nameWrapper}>
                <div className={styles.membersProfileName}>{profileData?.userName}</div>
                <div className={styles.membersProfileEnName}>{profileData?.userEnName}</div>
              </div>
              <div className={`${styles.formBorder} ${isScrolled ? styles.formBorderScrolled : ''}`} />
              <div className={styles.imgWrapper}>
                <Swiper
                  onBeforeInit={(swiper: any) => (swiperRef.current = swiper)}
                  onSlideChange={onSlideChange}
                  className="mySwiper"
                >
                  {profileData?.profileImage.map(
                    (temp: { url: string | undefined }, index: React.Key | null | undefined) => (
                      <SwiperSlide key={index}>
                        <div className={styles.imgSlider}>
                          <img src={temp?.url} alt={`Slide ${index}`} />
                        </div>
                      </SwiperSlide>
                    ),
                  )}
                </Swiper>
                <div className={styles.imageFooter}>
                  <button className={styles.imageArrowLeft} onClick={goToPrevSlide}>
                    <img alt="" src="/home/mobile/youtube-left.svg" />
                  </button>
                  <div className={styles.imagePaging}>
                    {profileData?.profileImage?.map((_item: any, index: number) =>
                      index === currentIndex ? (
                        <div key={index} className={styles.dotActive} />
                      ) : (
                        <div key={index} onClick={() => setCurrentIndex(index)} />
                      ),
                    )}
                  </div>
                  <button className={styles.imageArrowRight} onClick={goToNextSlide}>
                    <img alt="" src="/home/mobile/youtube-right.svg" />
                  </button>
                </div>
              </div>
              {profileData?.broadcastingCareer?.length > 0 && profileData?.broadcastingCareer?.[0] !== '' && (
                <>
                  <div className={styles.careerTitle}>방송경력</div>
                  <div className={styles.careerSubTitle}>
                    <ul>
                      {profileData?.broadcastingCareer?.map(
                        (
                          item:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined,
                          index: React.Key | null | undefined,
                        ) => (
                          <li key={index} className={styles.ollehTv}>
                            {item}
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </>
              )}
              {profileData?.eventCareer?.length > 0 && profileData?.eventCareer?.[0] !== '' && (
                <>
                  <div className={styles.careerTitle}>행사경력</div>
                  <div className={styles.careerSubTitle}>
                    <ul>
                      {profileData?.eventCareer?.map(
                        (
                          item:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined,
                          index: React.Key | null | undefined,
                        ) => (
                          <li key={index} className={styles.ollehTv}>
                            {item}
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </>
              )}
              {profileData?.etcCareer?.length > 0 && profileData?.etcCareer?.[0] !== '' && (
                <>
                  <div className={styles.careerTitle}>기타</div>
                  <div className={styles.careerSubTitle} style={{ marginBottom: 40 }}>
                    <ul>
                      {profileData?.etcCareer?.map(
                        (
                          item:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined,
                          index: React.Key | null | undefined,
                        ) => (
                          <li key={index} className={styles.ollehTv}>
                            {item}
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </>
              )}
              {profileData?.profileYoutube?.length > 0 && profileData?.profileYoutube?.[0] !== '' && (
                <div className={styles.videoIcon}>
                  <Swiper
                    onBeforeInit={(swiper: any) => (videoSwiperRef.current = swiper)}
                    onSlideChange={onVideoSlideChange}
                    className="videoSwiper"
                  >
                    {profileData?.profileYoutube.map((video: any, index: React.Key | null | undefined) => (
                      <SwiperSlide key={index}>
                        <iframe
                          key={index}
                          src={`https://www.youtube.com/embed/${video}?modestbranding=1`}
                          title="YouTube video player"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className={styles.youtubeFooter}>
                    <button className={styles.videoArrowLeft} onClick={goToPrevVideoSlide}>
                      <img alt="" src="/home/mobile/youtube-left.svg" />
                    </button>
                    <div className={styles.youtubePaging}>
                      {profileData?.profileYoutube?.map((_item: any, index: number) =>
                        index === currentVideoIndex ? (
                          <div key={index} className={styles.dotActive} />
                        ) : (
                          <div key={index} onClick={() => setCurrentVideoIndex(index)} />
                        ),
                      )}
                    </div>
                    <button className={styles.videoArrowRight} onClick={goToNextVideoSlide}>
                      <img alt="" src="/home/mobile/youtube-right.svg" />
                    </button>
                  </div>
                </div>
              )}

              <div className={styles.contactWrapper}>
                <div className={styles.subTitle}>
                  <b>010.3210.9345</b>로 전화 혹은 섭외문의
                  <br />
                  주시면 친절하게 답변 해 드리겠습니다.
                </div>
                <div className={styles.title}>
                  CONTACT
                  <br />
                  US
                </div>
              </div>

              <div className={styles.contactForm}>
                <div className={styles.placeholder}>
                  <FloatingLabel controlId="form-name" label="성함">
                    <Form.Control value={formName} placeholder="" onChange={(e) => setFormName(e.target.value)} />
                  </FloatingLabel>
                </div>
                <div className={styles.placeholder}>
                  <FloatingLabel controlId="form-phone" label="연락처">
                    <Form.Control value={formPhone} placeholder="" onChange={(e) => setFormPhone(e.target.value)} />
                  </FloatingLabel>
                </div>
                <div className={styles.placeholder}>
                  <FloatingLabel controlId="form-email" label="이메일 주소">
                    <Form.Control
                      value={formEmail}
                      type="email"
                      placeholder=""
                      onChange={(e) => setFormEmail(e.target.value)}
                    />
                  </FloatingLabel>
                </div>
                <div className={styles.placeholder}>
                  <FloatingLabel controlId="form-content" label="섭외 내용">
                    <Form.Control
                      value={formContent}
                      as="textarea"
                      placeholder=""
                      style={{ height: '200px' }}
                      onChange={(e) => setFormContent(e.target.value)}
                    />
                  </FloatingLabel>
                </div>

                <div className={styles.checkInfo}>
                  <div className={styles.checkboxDefault}>
                    <Form.Check
                      type={'checkbox'}
                      id={'form-checkbox'}
                      checked={formCheck}
                      onChange={(e) => setFormCheck(e.target.checked)}
                    />
                  </div>
                  <div className={styles.checkText}>
                    <span className={styles.under}>개인정보처리방침</span>
                    <span className={styles.underText}>에 동의합니다.</span>
                  </div>
                </div>

                <div className={styles.formBtn} onClick={() => submitForm()}>
                  <div>섭외 문의하기</div>
                  <img alt="" src={'/home/mobile/ico-chevron-right-20px.svg'} />
                </div>
                <div className={styles.formBtnFooter}></div>
              </div>
            </>
          )}
        </div>

        <div className={styles.footer}>
          <a href="/">
            <img className={styles.logo} alt="" src={'/about/mobile/logo-gray.svg'} />
          </a>
          <div className={styles.menu}>
            <a href="/">
              <b className={styles.text}>Home</b>
            </a>
            <a href="/pages/about">
              <b className={styles.text}>About us</b>
            </a>
            <a href="/pages/profiles">
              <b className={styles.text}>Members</b>
            </a>
            <a href="/pages/news">
              <b className={styles.text}>News/notice</b>
            </a>
          </div>
        </div>
        <div className={styles.copy}>
          <div className={styles.copyText}>
            <div>개인정보처리방침</div>
            <div>이용약관</div>
          </div>
          <div className={styles.copyright}>
            Copyright © 2013 by <b>KFBA</b> All right reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileProfilePage;
