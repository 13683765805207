import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper as SwiperCore } from 'swiper/types';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../utils/initFirebase';
import { doc, getDoc, DocumentData, setDoc } from 'firebase/firestore';
import FullScreenLoader from '../../components/FullScreenLoader';
import styles from './profile.module.css';
import MIndex from './mIndex';

import { notification } from 'antd';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

const ProfilePage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<DocumentData | undefined>(undefined);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
  const profileListRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperCore>();
  const videoSwiperRef = useRef<SwiperCore>();
  const [profileListHeight, setProfileListHeight] = useState(typeof window !== 'undefined' ? window.innerHeight : 0);

  useEffect(() => {
    if (profileListRef.current) {
      setProfileListHeight(profileListRef?.current?.offsetHeight);
    }
  }, [profileData]);

  const getProfile = async () => {
    setIsLoading(true);
    const name = userId;

    if (name) {
      try {
        const docRef = doc(db, 'profile', name);
        const docSnap = await getDoc(docRef);
        docSnap?.data();
        if (docSnap?.data()) {
          setProfileData(docSnap?.data());
        } else {
          navigate(`/`);
        }
        setIsLoading(false);
      } catch (e) {
        navigate(`/`);
        setIsLoading(false);
      }
    } else {
      navigate(`/`);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('welcome to kfba profile page');
    getProfile();
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onSlideChange = (swiper: SwiperCore) => {
    setCurrentIndex(swiper.realIndex);
  };

  const goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const onVideoSlideChange = (swiper: SwiperCore) => {
    setCurrentVideoIndex(swiper.realIndex);
  };

  const goToPrevVideoSlide = () => {
    if (videoSwiperRef.current) {
      videoSwiperRef.current.slidePrev();
    }
  };

  const goToNextVideoSlide = () => {
    if (videoSwiperRef.current) {
      videoSwiperRef.current.slideNext();
    }
  };

  const [hover, setHover] = useState(false);
  const imgSrc = hover ? '/home/vector-401.svg' : '/home/vector-40.svg';

  const [formName, setFormName] = useState('');
  const [formPhone, setFormPhone] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formContent, setFormContent] = useState('');
  const [formCheck, setFormCheck] = useState(false);
  const [shouldUpdateProfile, setShouldUpdateProfile] = useState<boolean>(false);
  const currentDate = new Date().toISOString().split('T')[0];
  const timestamp = Date.now();

  const contactId = `${currentDate}-${timestamp}`;

  const setProfile = async () => {
    try {
      await setDoc(doc(db, 'contact', contactId), {
        formName: formName,
        formPhone: formPhone,
        formEmail: formEmail,
        formContent: formContent,
        formCheck: formCheck,
        isRead: false,
      });
      notification.success({
        message: 'Contact Success',
        description: '섭외 문의가 완료되었습니다.',
      });
      setFormName('');
      setFormPhone('');
      setFormEmail('');
      setFormContent('');
      setFormCheck(false);
    } catch (e) {
      notification.error({
        message: 'Contact failed',
        description: `섭외 문의 입력 폼을 다시 확인해 주세요.`,
      });
    }
  };

  const submitForm = async () => {
    if (formName === '') {
      alert('성함을 입력해주세요.');
      return;
    }
    if (formPhone === '') {
      alert('연락처를 입력해주세요.');
      return;
    }
    if (formEmail === '') {
      alert('이메일을 입력해주세요.');
      return;
    }
    if (formContent === '') {
      alert('섭외 내용을 입력해주세요.');
      return;
    }
    if (!formCheck) {
      alert('개인정보처리방침에 동의해주세요.');
      return;
    }
    setShouldUpdateProfile(true);
  };

  useEffect(() => {
    if (shouldUpdateProfile) {
      setProfile();
      setShouldUpdateProfile(false);
    }
  }, [shouldUpdateProfile]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(currentIndex);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (videoSwiperRef.current) {
      videoSwiperRef.current.slideTo(currentVideoIndex);
    }
  }, [currentVideoIndex]);

  return (
    <>
      <div className="webDiv">
        <div className={styles.pcHeaderContainer}>
          <div className={`${styles.pcHeaderContainer} ${isScrolled ? styles.pcHeaderScrolled : ''}`}>
            <div className={styles.pcHeader}>
              <div className={`${styles.gnbWhite} `}>
                <a href="/">
                  <img
                    className={styles.logoWhiteIcon}
                    alt=""
                    src={`${isScrolled ? '/home/logoblack.svg' : '/home/logoblack.svg'}`}
                  />
                </a>
                <div className={styles.menu}>
                  <a href="/">
                    <b className={styles.home}>Home</b>
                  </a>
                  <a href="/pages/about">
                    <b className={styles.home}>About us</b>
                  </a>
                  <a href="/pages/profiles">
                    <b className={styles.home}>Members</b>
                  </a>
                  <a href="/pages/news">
                    <b className={styles.home}>News/notice</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.pcMembersDetailContainer}>
          <div
            className={styles.pcMembersDetail}
            style={{ height: profileListHeight > 1100 ? 1100 + profileListHeight : 2183 }}
          >
            {isLoading ? (
              <FullScreenLoader />
            ) : (
              <>
                <div className={styles.profileKimjinyoungIcon}>
                  <div className={styles.indicatorImage}>
                    {profileData?.profileImage?.map((_item: any, index: number) =>
                      index === currentIndex ? (
                        <div key={index} className={styles.indicator1} />
                      ) : (
                        <div key={index} className={styles.indicator2} onClick={() => setCurrentIndex(index)} />
                      ),
                    )}
                  </div>
                  <Swiper
                    onBeforeInit={(swiper: any) => (swiperRef.current = swiper)}
                    onSlideChange={onSlideChange}
                    className="mySwiper"
                  >
                    {profileData?.profileImage.map((temp: { url: string | undefined }, index: number) => (
                      <SwiperSlide key={index}>
                        <img src={temp?.url} alt={`Slide ${index}`} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <button className={styles.imageArrowLeft} onClick={goToPrevSlide}>
                    <img alt="" src="/profile/icon-video-arrow-left-24px.svg" />
                  </button>
                  <button className={styles.imageArrowRight} onClick={goToNextSlide}>
                    <img alt="" src="/profile/icon-video-arrow-right-24px.svg" />
                  </button>
                </div>

                <div className={styles.membersParent}>
                  <div className={styles.membersText}>MEMBERS</div>
                  <div className={styles.iconArrowRight12px}>
                    <div className={styles.bg} />
                    <img className={styles.vectorIcon1} alt="" src="/profile/vector.svg" />
                  </div>
                  <div className={styles.kimJinYoung}>{profileData?.userEnName}</div>
                </div>

                <div className={styles.frameParent} ref={profileListRef}>
                  {profileData?.broadcastingCareer?.length > 0 && profileData?.broadcastingCareer?.[0] !== '' && (
                    <div className={styles.parent}>
                      <div className={styles.div3}>방송경력</div>
                      <div className={styles.ollehTvContainer}>
                        <ul className={styles.ollehTvTbn}>
                          {profileData?.broadcastingCareer?.map(
                            (
                              item:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined,
                              index: React.Key | null | undefined,
                            ) => (
                              <li key={index} className={styles.ollehTv}>
                                {item}
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                  {profileData?.eventCareer?.length > 0 && profileData?.eventCareer?.[0] !== '' && (
                    <div className={styles.parent}>
                      <div className={styles.div3}>행사경력</div>
                      <div className={styles.ollehTvContainer}>
                        <ul className={styles.ollehTvTbn}>
                          {profileData?.eventCareer?.map(
                            (
                              item:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined,
                              index: React.Key | null | undefined,
                            ) => (
                              <li key={index} className={styles.ollehTv}>
                                {item}
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                  {profileData?.etcCareer?.length > 0 && profileData?.etcCareer?.[0] !== '' && (
                    <div className={styles.parent}>
                      <div className={styles.div3}>기타</div>
                      <div className={styles.ollehTvContainer}>
                        <ul className={styles.ollehTvTbn}>
                          {profileData?.etcCareer?.map(
                            (
                              item:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined,
                              index: React.Key | null | undefined,
                            ) => (
                              <li key={index} className={styles.ollehTv}>
                                {item}
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>

                <div className={styles.title}>
                  <div className={styles.title1}>
                    <div className={styles.div}>{profileData?.userName}</div>
                    <div className={styles.kimJinYoung1}>{profileData?.userEnName}</div>
                  </div>
                </div>
                <div className={styles.lineName} />
                <div className={styles.footer}>
                  <div className={styles.menu1}>
                    <a href="/">
                      <img className={styles.kfbaIcon} alt="" src="/profile/kfba.svg" />
                    </a>
                    <div className={styles.menu2}>
                      <a href="/">
                        <div className={styles.history}>HOME</div>
                      </a>
                      <a href="/pages/about">
                        <div className={styles.history}>About US</div>
                      </a>
                      <a href="/pages/profiles">
                        <div className={styles.history}>Members</div>
                      </a>
                      <a href="/pages/news">
                        <div className={styles.history}>NEWS/NOTICE</div>
                      </a>
                    </div>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.div}>
                      <span>{`Copyright © 2013 by `}</span>
                      <b className={styles.kfba}>&nbsp;KFBA&nbsp;</b>
                      <span> All right reserved.</span>
                    </div>
                    <div className={styles.buttons}>
                      <div className={styles.div8}>개인정보처리방침</div>
                      <div className={styles.div8}>이용약관</div>
                    </div>
                  </div>
                </div>

                {/* currentVideoIndex */}
                {profileData?.profileYoutube?.length > 0 && profileData?.profileYoutube?.[0] !== '' && (
                  <>
                    <div className={styles.indicator}>
                      {profileData?.profileYoutube?.map((_item: any, index: number) =>
                        index === currentVideoIndex ? (
                          <div key={index} className={styles.indicator1} />
                        ) : (
                          <div key={index} className={styles.indicator2} onClick={() => setCurrentVideoIndex(index)} />
                        ),
                      )}
                    </div>

                    <div className={styles.videoIcon}>
                      <Swiper
                        onBeforeInit={(swiper: any) => (videoSwiperRef.current = swiper)}
                        onSlideChange={onVideoSlideChange}
                        className="mySwiper"
                      >
                        {profileData?.profileYoutube.map((video: any, index: React.Key | null | undefined) => (
                          <SwiperSlide key={index}>
                            <iframe
                              key={index}
                              width="600"
                              height="338"
                              src={`https://www.youtube.com/embed/${video}?modestbranding=1`}
                              title="YouTube video player"
                              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <button className={styles.videoArrowLeft} onClick={goToPrevVideoSlide}>
                        <img alt="" src="/profile/icon-video-arrow-left-24px.svg" />
                      </button>
                      <button className={styles.videoArrowRight} onClick={goToNextVideoSlide}>
                        <img alt="" src="/profile/icon-video-arrow-right-24px.svg" />
                      </button>
                    </div>
                  </>
                )}

                <div
                  className={styles.section04}
                  id="contact"
                  style={{ top: profileListHeight > 1100 ? 516 + profileListHeight : 1600 }}
                >
                  <div className={styles.line} />
                  <div className={styles.line1} />
                  <div className={styles.line2} />
                  <div className={styles.line3} />
                  <div className={styles.line4} />
                  <div className={styles.div5}>
                    <p className={styles.p}>
                      <span className={styles.span1}>010.3210.9345</span>
                      <span className={styles.span2}>로 전화 혹은 섭외문의 주시면</span>
                    </p>
                    <p className={styles.p1}>친절하게 답변 해 드리겠습니다.</p>
                  </div>
                  <div className={styles.checkInfo}>
                    <div className={styles.checkboxDefault}>
                      <Form.Check
                        type={'checkbox'}
                        id={'form-checkbox'}
                        checked={formCheck}
                        onChange={(e) => setFormCheck(e.target.checked)}
                      />
                    </div>
                    <div className={styles.div6}>
                      <span className={styles.span3}>개인정보처리방침</span>
                      <span className={styles.span4}>에 동의합니다.</span>
                    </div>
                  </div>
                  <div className={styles.placeholder}>
                    <FloatingLabel controlId="form-name" label="성함" className="mb-3">
                      <Form.Control value={formName} placeholder="" onChange={(e) => setFormName(e.target.value)} />
                    </FloatingLabel>
                  </div>
                  <div className={styles.div10}>
                    <FloatingLabel controlId="form-phone" label="연락처" className="mb-3">
                      <Form.Control value={formPhone} placeholder="" onChange={(e) => setFormPhone(e.target.value)} />
                    </FloatingLabel>
                  </div>
                  <div className={styles.div7}>
                    <FloatingLabel controlId="form-email" label="이메일 주소" className="mb-3">
                      <Form.Control
                        value={formEmail}
                        type="email"
                        placeholder=""
                        onChange={(e) => setFormEmail(e.target.value)}
                      />
                    </FloatingLabel>
                  </div>
                  <div className={styles.div9}>
                    <FloatingLabel controlId="form-content" label="섭외 내용">
                      <Form.Control
                        value={formContent}
                        as="textarea"
                        placeholder=""
                        style={{ height: '200px' }}
                        onChange={(e) => setFormContent(e.target.value)}
                      />
                    </FloatingLabel>
                  </div>
                  <div className={styles.contents}>
                    <div className={styles.contact}>contact</div>
                    <div className={styles.us}>us</div>
                  </div>
                  <div
                    className={styles.div11}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={() => submitForm()}
                  >
                    <div className={styles.parent2}>
                      <div className={styles.div1}>섭외 문의하기</div>
                      <div className={styles.checkboxDefault}>
                        <div className={styles.icoChevronRight16pxInner} />
                        <img className={styles.vectorIcon} alt="" src={imgSrc} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* backgroud */}
        {/* <div
          className={styles.contactBg}
          style={{ top: profileListHeight > 800 ? 516 + profileListHeight : 1320 }}
        ></div>
        <div
          className={styles.footerBg}
          style={{ top: profileListHeight > 800 ? 1016 + profileListHeight : 1820 }}
        ></div> */}
      </div>
      <MIndex />
    </>
  );
};

export default ProfilePage;
