import React, { useState, useEffect } from 'react';
import { Button, notification, Space, Spin } from 'antd';

import ScreenLoader from '../../components/ScreenLoader';
import FullScreenLoader from '../../components/FullScreenLoader';
import styles from './mobile.module.css';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { db } from '../../utils/initFirebase';
import { collection, getDocs } from 'firebase/firestore';

type TabName = 'NEWS' | 'NOTICE';

interface DataType {
  id: string;
  date: string;
  text: string;
  title: string;
  subTitle: string;
  representFileUrl: any;
  isApp: boolean;
  isNotice?: boolean;
}

const MobileNewsPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<{ [key: string]: boolean }>({});
  const [imageLoading2, setImageLoading2] = useState<{ [key: string]: boolean }>({});
  const [newsListData, setNewsListData] = useState<DataType[]>([]);
  const [noticeListData, setNoticeListData] = useState<DataType[]>([]);

  const [formNews, setFormNews] = useState('');
  const [formNotice, setFormNotice] = useState('');
  const [filteredNewsData, setFilteredNewsData] = useState<DataType[]>([]);
  const [filteredNoticeData, setFilteredNoticeData] = useState<DataType[]>([]);

  const getNewsAndNoticesList = async () => {
    setIsLoading(true);

    try {
      const newsCollectionRef = collection(db, 'news');
      const noticeCollectionRef = collection(db, 'notice');

      const [newsSnapshot, noticeSnapshot] = await Promise.all([
        getDocs(newsCollectionRef),
        getDocs(noticeCollectionRef),
      ]);

      const newsDataList = newsSnapshot.docs.map((newsDoc) => ({
        id: newsDoc.id,
        ...newsDoc.data(),
      })) as DataType[];

      const noticeDataList = noticeSnapshot.docs.map((noticeDoc) => ({
        id: noticeDoc.id,
        ...noticeDoc.data(),
      })) as DataType[];

      const newsDataIsAppList = newsDataList.filter((item) => item?.isApp === true);

      const noticeDataListList = noticeDataList.filter((item) => item?.isApp === true);

      setNewsListData(newsDataIsAppList.sort((a, b) => b?.date.localeCompare(a?.date)));
      setFilteredNewsData(newsDataIsAppList.sort((a, b) => b?.date.localeCompare(a?.date)));
      setNoticeListData(
        noticeDataListList
          .sort((a, b) => b?.date.localeCompare(a?.date))
          .sort((a, b) => (b.isNotice === a.isNotice ? 0 : b.isNotice ? 1 : -1)),
      );
      setFilteredNoticeData(
        noticeDataListList
          .sort((a, b) => b?.date.localeCompare(a?.date))
          .sort((a, b) => (b.isNotice === a.isNotice ? 0 : b.isNotice ? 1 : -1)),
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: '뉴스와 공지 리스트 데이터 오류',
        description: `Error getting documents: ${e}`,
      });
    }
  };

  useEffect(() => {
    console.log('welcome to kfba news and notice list page');
    getNewsAndNoticesList();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [activeTab, setActiveTab] = useState<TabName>('NEWS');

  const handleTabClick = (tabName: TabName) => {
    setActiveTab(tabName);
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const articlesPerPage = 8;
  const totalArticles = filteredNewsData?.length || 0;

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredNewsData?.slice(indexOfFirstArticle, indexOfLastArticle) || [];

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++) {
    pageNumbers.push(i);
  }

  const [currentPage2, setCurrentPage2] = useState<number>(1);
  const articlesPerPage2 = 8;
  const totalArticles2 = filteredNoticeData?.length || 0;

  const indexOfLastArticle2 = currentPage2 * articlesPerPage2;
  const indexOfFirstArticle2 = indexOfLastArticle2 - articlesPerPage2;
  const currentArticles2 = filteredNoticeData?.slice(indexOfFirstArticle2, indexOfLastArticle2) || [];

  const pageNumbers2 = [];
  for (let i = 1; i <= Math.ceil(totalArticles2 / articlesPerPage2); i++) {
    pageNumbers2.push(i);
  }

  const handleNewsKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      const filteredNews = newsListData.filter((profile) => profile?.title && profile?.title.includes(formNews));
      setFilteredNewsData(filteredNews);

      if (formNews === null || formNews === '') {
        setFilteredNewsData(newsListData);
      }

      setCurrentPage(1);
    }
  };

  const handleSearchNewsData = () => {
    const filteredNews = newsListData.filter((profile) => profile?.title && profile?.title.includes(formNews));
    setFilteredNewsData(filteredNews);

    if (formNews === null || formNews === '') {
      setFilteredNewsData(newsListData);
    }

    setCurrentPage(1);
  };

  const handleNoticeKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      const filteredNotice = noticeListData.filter((profile) => profile?.title && profile?.title.includes(formNotice));
      setFilteredNoticeData(filteredNotice);

      if (formNotice === null || formNotice === '') {
        setFilteredNoticeData(noticeListData);
      }

      setCurrentPage2(1);
    }
  };

  const handleSearchNoticeData = () => {
    const filteredNotice = noticeListData.filter((profile) => profile?.title && profile?.title.includes(formNotice));
    setFilteredNoticeData(filteredNotice);

    if (formNotice === null || formNotice === '') {
      setFilteredNoticeData(noticeListData);
    }

    setCurrentPage2(1);
  };

  useEffect(() => {
    const initialLoadingState: { [key: string]: boolean } = {};
    currentArticles.forEach((item) => {
      initialLoadingState[item.id] = true;
    });
    setImageLoading(initialLoadingState);
  }, [currentPage, filteredNewsData]);

  useEffect(() => {
    const initialLoadingState: { [key: string]: boolean } = {};
    currentArticles2.forEach((item) => {
      initialLoadingState[item.id] = true;
    });
    setImageLoading2(initialLoadingState);
  }, [currentPage2, filteredNoticeData]);

  const handleImageLoad = (id: string) => {
    setImageLoading((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleImageLoad2 = (id: string) => {
    setImageLoading2((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  return (
    <>
      <div className="mobileDiv">
        <div className={`${styles.header} ${isScrolled ? styles.headerScrolled : ''}`}>
          <a href="/">
            <img className={styles.logo} alt="" src={'/about/mobile/logo-black-mo.svg'} />
          </a>
          <button className={styles.menuIcon} onClick={toggleMenu}>
            <img alt="" src={'/about/mobile/icon-menu-24px.svg'} />
          </button>
        </div>
        <div className={`${styles.headerBg} ${isMenuOpen ? styles.active : ''}`}>
          <button className={styles.menuIcon} onClick={toggleMenu}>
            <img alt="" src={'/about/mobile/icon-menu-24px2.svg'} />
          </button>
          <div className={styles.menu}>
            <a href="/">
              <b className={styles.text}>Home</b>
            </a>
            <a href="/pages/about">
              <b className={styles.text}>About us</b>
            </a>
            <a href="/pages/profiles">
              <b className={styles.text}>Members</b>
            </a>
            <a href="/pages/news">
              <b className={styles.text}>News/notice</b>
            </a>
          </div>
        </div>

        <div className={styles.container}>
          <div className={styles.containerTitleWrapper}>
            <div className={styles.title}>NEWS</div>
            <div className={styles.subTitle}>
              <b>KFBA</b>의 행사, 파트너쉽, 시상식 등<br />
              다양한 소식들을 만나보세요!
            </div>
          </div>

          <div className={styles.tab}>
            <button
              className={activeTab === 'NEWS' ? `${styles.tabTitle} ${styles.tabActive}` : styles.tabTitle}
              onClick={() => handleTabClick('NEWS')}
            >
              NEWS
            </button>
            <button
              className={activeTab === 'NOTICE' ? `${styles.tabTitle2} ${styles.tabActive}` : styles.tabTitle2}
              onClick={() => handleTabClick('NOTICE')}
            >
              NOTICE
            </button>
          </div>

          {isLoading ? (
            <ScreenLoader height={600} />
          ) : (
            <>
              {activeTab === 'NEWS' ? (
                <>
                  <div className={styles.searchBar1}>
                    <div className={styles.div29}>
                      <InputGroup size="sm">
                        <Form.Control
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"
                          placeholder="뉴스를 검색해주세요"
                          value={formNews}
                          onChange={(e) => setFormNews(e.target.value)}
                          onKeyDown={handleNewsKeyDown}
                        />
                      </InputGroup>
                    </div>
                    <button onClick={handleSearchNewsData}>
                      <img className={styles.icoChevronUp20px} alt="" src="/profileList/icosearch20px.svg" />
                    </button>
                  </div>

                  {currentArticles.length === 0 && (
                    <div className={styles.membersProfileListEmpty}>
                      <div className={styles.noDataText}>검색 결과가 없습니다.</div>
                      <div className={styles.noDataButton}>
                        <Button
                          onClick={() => {
                            setFilteredNewsData(newsListData);
                            setFormNews('');
                          }}
                        >
                          검색 결과 초기화
                        </Button>
                      </div>
                    </div>
                  )}

                  <div className={styles.membersProfileList}>
                    {currentArticles.map((item, i) => (
                      <a href={`/pages/news/${item?.id}`} key={i}>
                        <div className={styles.membersProfileWrapper}>
                          {imageLoading[item.id] && (
                            <>
                              <Space style={{ minWidth: 68, height: 68, justifyContent: 'center' }}>
                                <Spin size="small" />
                              </Space>
                            </>
                          )}
                          <img
                            className={styles.membersProfileImage}
                            alt=""
                            src={item?.representFileUrl?.[0]?.url ? item?.representFileUrl?.[0]?.url : '/news/news.png'}
                            onLoad={() => handleImageLoad(item.id)}
                            onError={() => handleImageLoad(item.id)}
                            style={{ display: imageLoading[item.id] ? 'none' : 'block' }}
                          />
                          <div className={styles.membersProfileNameWrapper}>
                            <div className={styles.membersProfileTitle}>{item?.title}</div>
                            <div className={styles.membersProfileSubTitle}>{item?.subTitle}</div>
                            <div className={styles.membersProfileFooter}>
                              <div className={styles.membersProfileDate}>{item?.date}</div>
                              <div className={styles.membersProfileMore}>more</div>
                              <img className={styles.membersProfilePlus} alt="" src="/news/icon-plus.png" />
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}

                    {/* pagination */}
                    <div className={styles.parent}>
                      {pageNumbers.map((number) => (
                        <button
                          key={number}
                          onClick={() => setCurrentPage(number)}
                          className={`${currentPage === number ? styles.active : ''}`}
                        >
                          {number}
                        </button>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.searchBar1}>
                    <div className={styles.div29}>
                      <InputGroup size="sm">
                        <Form.Control
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"
                          placeholder="공지를 검색해주세요"
                          value={formNotice}
                          onChange={(e) => setFormNotice(e.target.value)}
                          onKeyDown={handleNoticeKeyDown}
                        />
                      </InputGroup>
                    </div>
                    <button onClick={handleSearchNoticeData}>
                      <img className={styles.icoChevronUp20px} alt="" src="/profileList/icosearch20px.svg" />
                    </button>
                  </div>

                  {currentArticles2.length === 0 && (
                    <div className={styles.membersProfileListEmpty}>
                      <div className={styles.noDataText}>검색 결과가 없습니다.</div>
                      <div className={styles.noDataButton}>
                        <Button
                          onClick={() => {
                            setFilteredNoticeData(noticeListData);
                            setFormNotice('');
                          }}
                        >
                          검색 결과 초기화
                        </Button>
                      </div>
                    </div>
                  )}

                  <div className={styles.membersProfileList}>
                    {currentArticles2.map((item, i) => (
                      <a href={`/pages/notice/${item?.id}`} key={i}>
                        <div className={styles.membersProfileWrapper}>
                          {imageLoading2[item.id] && (
                            <>
                              <Space style={{ minWidth: 68, height: 68, justifyContent: 'center' }}>
                                <Spin size="small" />
                              </Space>
                            </>
                          )}
                          <img
                            className={styles.membersProfileImage}
                            alt=""
                            src={item?.representFileUrl?.[0]?.url ? item?.representFileUrl?.[0]?.url : '/news/news.png'}
                            onLoad={() => handleImageLoad2(item.id)}
                            onError={() => handleImageLoad2(item.id)}
                            style={{ display: imageLoading2[item.id] ? 'none' : 'block' }}
                          />
                          {item?.isNotice && <div className={styles.membersProfileNotice}>공지</div>}
                          <div className={styles.membersProfileNameWrapper}>
                            <div className={styles.membersProfileTitle}>{item?.title}</div>
                            <div className={styles.membersProfileSubTitle}>{item?.subTitle}</div>
                            <div className={styles.membersProfileFooter}>
                              <div className={styles.membersProfileDate}>{item?.date}</div>
                              <div className={styles.membersProfileMore}>more</div>
                              <img className={styles.membersProfilePlus} alt="" src="/news/icon-plus.png" />
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}

                    {/* pagination */}
                    <div className={styles.parent}>
                      {pageNumbers2.map((number) => (
                        <button
                          key={number}
                          onClick={() => setCurrentPage2(number)}
                          className={`${currentPage2 === number ? styles.active : ''}`}
                        >
                          {number}
                        </button>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className={styles.footer}>
          <a href="/">
            <img className={styles.logo} alt="" src={'/about/mobile/logo-gray.svg'} />
          </a>
          <div className={styles.menu}>
            <a href="/">
              <b className={styles.text}>Home</b>
            </a>
            <a href="/pages/about">
              <b className={styles.text}>About us</b>
            </a>
            <a href="/pages/profiles">
              <b className={styles.text}>Members</b>
            </a>
            <a href="/pages/news">
              <b className={styles.text}>News/notice</b>
            </a>
          </div>
        </div>
        <div className={styles.copy}>
          <div className={styles.copyText}>
            <div>개인정보처리방침</div>
            <div>이용약관</div>
          </div>
          <div className={styles.copyright}>
            Copyright © 2013 by <b>KFBA</b> All right reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNewsPage;
