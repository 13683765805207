import { useEffect, useState, RefObject } from 'react';

const useOnScreen = (ref: RefObject<Element>, rootMargin = '0px') => {
  // 요소가 화면에 보이는지 여부를 저장하는 state
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
      rootMargin,
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, rootMargin]); // ref와 rootMargin에 의존

  return isIntersecting;
};

export default useOnScreen;
