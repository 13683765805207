import styled from 'styled-components';
import { Layout, Space, Spin } from 'antd';

const { Content } = Layout;

const FullScreenStyle = styled(Layout)`
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
`;

const ContentStyle = styled(Content)`
  margin: 0 auto;
  display: flex;
`;

const FullScreenLoader = () => {
  return (
    <FullScreenStyle>
      <ContentStyle>
        <Space>
          <Spin size="large" />
        </Space>
      </ContentStyle>
    </FullScreenStyle>
  );
};

export default FullScreenLoader;
