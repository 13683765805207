import React, { useState, useEffect } from 'react';
import styles from './mobile.module.css';

const MobileAboutPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 60;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="mobileDiv">
        <div className={`${styles.header} ${isScrolled ? styles.headerScrolled : ''}`}>
          <a href="/">
            <img className={styles.logo} alt="" src={'/about/mobile/logo-black-mo.svg'} />
          </a>
          <button className={styles.menuIcon} onClick={toggleMenu}>
            <img alt="" src={'/about/mobile/icon-menu-24px.svg'} />
          </button>
        </div>
        <div className={`${styles.headerBg} ${isMenuOpen ? styles.active : ''}`}>
          <button className={styles.menuIcon} onClick={toggleMenu}>
            <img alt="" src={'/about/mobile/icon-menu-24px2.svg'} />
          </button>
          <div className={styles.menu}>
            <a href="/">
              <b className={styles.text}>Home</b>
            </a>
            <a href="/pages/about">
              <b className={styles.text}>About us</b>
            </a>
            <a href="/pages/profiles">
              <b className={styles.text}>Members</b>
            </a>
            <a href="/pages/news">
              <b className={styles.text}>News/notice</b>
            </a>
          </div>
        </div>

        <div className={styles.container}>
          <div className={styles.containerImg}>
            <div>Association President</div>
            <img alt="" src={'/about/mobile/img-about-us.png'} />
          </div>
          <div className={styles.containerText}>
            <div className={styles.hello}>“안녕하십니까!”</div>
            <div className={styles.text}>
              2013년 작은 모임으로 시작된 한국방송진행자연합(KFBA)은 지난 10년간 국내 최대, 국내 유일의 현업에서
              활동중인 프리랜서 방송진행자들의 온오프라인 커뮤니티로 성장하여 자리잡았습니다. <br />
              <br />
              현재 KFBA 안에는 아나운서, 쇼호스트, 행사MC, 외국어MC, 인플루언서 등 다양한 분야에서 활동하는 방송진행자
              300여명이 함께 활동하고 있습니다. <br />
              <br />
              KFBA는 방송진행자들의 권익과 복지를 위하여 계속해서 최선을 다할것이며 또한 실력있는 방송진행자들이 더욱
              활발히 활동할수 있도록 후원해 나갈 것입니다! 방송과 행사 현장에서 여러분과 만나뵙겠습니다
            </div>
            <div className={styles.kfba}>한국 방송진행자 연합회장</div>
            <div className={styles.name}>이기상</div>
          </div>
        </div>

        <div className={styles.footer}>
          <a href="/">
            <img className={styles.logo} alt="" src={'/about/mobile/logo-gray.svg'} />
          </a>
          <div className={styles.menu}>
            <a href="/">
              <b className={styles.text}>Home</b>
            </a>
            <a href="/pages/about">
              <b className={styles.text}>About us</b>
            </a>
            <a href="/pages/profiles">
              <b className={styles.text}>Members</b>
            </a>
            <a href="/pages/news">
              <b className={styles.text}>News/notice</b>
            </a>
          </div>
        </div>
        <div className={styles.copy}>
          <div className={styles.copyText}>
            <div>개인정보처리방침</div>
            <div>이용약관</div>
          </div>
          <div className={styles.copyright}>
            Copyright © 2013 by <b>KFBA</b> All right reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileAboutPage;
