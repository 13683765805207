import React, { useState, useEffect, useRef } from 'react';
import { notification } from 'antd';

import ScreenLoader from '../../components/ScreenLoader';
import styles from './profileList.module.css';

import { motion } from 'framer-motion';
import useOnScreen from '../../hooks/useOnScreen';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { db } from '../../utils/initFirebase';
import { collection, getDocs } from 'firebase/firestore';
import MIndex from './mIndex';

interface DataType {
  isApp: boolean;
  id: string;
  broadcastingCareer: string[];
  etcCareer: string[];
  eventCareer: string[];
  facebookLink: string;
  youtubeLink: string;
  profileImage: string[];
  profileYoutube: string[];
  representProfileImage: Array<{ url: string }>;
  userEnName: string;
  userName: string;
  userUrl: string;
  category: string[];
}

type CheckboxState = {
  [key: string]: boolean;
};

const ProfileListPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  const [hover, setHover] = useState(false);
  const imgSrc = hover ? '/profileList/ico-chevron-up-20px.svg' : '/profileList/ico-chevron-down-20px.svg';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<{ [key: string]: boolean }>({});
  const [profileListData, setProfileListData] = useState<DataType[]>([]);
  const [filteredData, setFilteredData] = useState<DataType[]>([]);

  const profileListRef = useRef<HTMLDivElement>(null);
  const [profileListHeight, setProfileListHeight] = useState(typeof window !== 'undefined' ? window.innerHeight : 0);
  useEffect(() => {
    if (profileListRef.current) {
      setProfileListHeight(profileListRef?.current?.offsetHeight);
    }
  }, [filteredData]);

  const shuffleArray = (array: any[]) => {
    const result = array.slice();
    for (let i = result.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [result[i], result[j]] = [result[j], result[i]];
    }
    return result;
  };

  const getProfileList = async () => {
    setIsLoading(true);

    try {
      const collectionRef = collection(db, 'profile');
      const snapshot = await getDocs(collectionRef);

      const dataList = snapshot.docs.map((collectionDoc) => ({
        id: collectionDoc.id,
        ...collectionDoc.data(),
      })) as DataType[];

      const filteredList = dataList.filter((item) => item?.isApp === true);

      const shuffledFilteredList = shuffleArray(filteredList);

      setProfileListData(shuffledFilteredList);
      setFilteredData(shuffledFilteredList);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: '프로필 리스트 데이터 오류',
        description: `Error getting cached document: ${e}`,
      });
    }
  };

  useEffect(() => {
    console.log('welcome to kfba profile list page');
    getProfileList();
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const [category, setCategory] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState<CheckboxState>({
    eventMC: true,
    russiaMC: true,
    englishMC: true,
    chineseMC: true,
    japaneseMC: true,
    announcer: true,
    reporter: true,
    weatherCaster: true,
    sportsCaster: true,
    showHost: true,
    simultaneousInterpreter: true,
    presenter: true,
    corporateLecture: true,
    speechLecture: true,
    sportsAnnouncer: true,
  });
  const [formName, setFormName] = useState('');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, label: string) => {
    const { checked } = event.target;
    setIsChecked({ ...isChecked, [label]: checked });

    if (checked && !category.includes(label)) {
      setCategory([...category, label]);
    } else {
      setCategory(category.filter((item) => item !== label));
    }
  };

  const handleFilterData = () => {
    const newFilteredData = profileListData.filter((profile) =>
      profile?.category?.some((cat: string) => isChecked[cat]),
    );
    setFilteredData(newFilteredData);
    setFormName('');
  };

  const handleSearchData = () => {
    const filteredProfiles = profileListData.filter(
      (profile) => profile?.userName && profile?.userName.includes(formName),
    );
    setIsChecked({
      eventMC: true,
      russiaMC: true,
      englishMC: true,
      chineseMC: true,
      japaneseMC: true,
      announcer: true,
      reporter: true,
      weatherCaster: true,
      sportsCaster: true,
      showHost: true,
      simultaneousInterpreter: true,
      presenter: true,
      corporateLecture: true,
      speechLecture: true,
      sportsAnnouncer: true,
    });
    setFilteredData(filteredProfiles);

    if (formName === null || formName === '') {
      setFilteredData(profileListData);
    }
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      const filteredProfiles = profileListData.filter(
        (profile) => profile?.userName && profile?.userName.includes(formName),
      );
      setIsChecked({
        eventMC: true,
        russiaMC: true,
        englishMC: true,
        chineseMC: true,
        japaneseMC: true,
        announcer: true,
        reporter: true,
        weatherCaster: true,
        sportsCaster: true,
        showHost: true,
        simultaneousInterpreter: true,
        presenter: true,
        corporateLecture: true,
        speechLecture: true,
        sportsAnnouncer: true,
      });
      setFilteredData(filteredProfiles);

      if (formName === null || formName === '') {
        setFilteredData(profileListData);
      }
    }
  };

  useEffect(() => {
    const initialLoadingState: { [key: string]: boolean } = {};
    profileListData.forEach((item) => {
      initialLoadingState[item.id] = true;
    });
    setImageLoading(initialLoadingState);
  }, [profileListData]);

  const handleImageLoad = (id: string) => {
    setImageLoading((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  return (
    <>
      <div className="webDiv">
        <div className={styles.pcHeaderContainer}>
          <div className={`${styles.pcHeaderContainer} ${isScrolled ? styles.pcHeaderScrolled : ''}`}>
            <div className={styles.pcHeader}>
              <div className={`${styles.gnbWhite} `}>
                <a href="/">
                  <img
                    className={styles.logoWhiteIcon}
                    alt=""
                    src={`${isScrolled ? '/home/logoblack.svg' : '/home/logoblack.svg'}`}
                  />
                </a>
                <div className={styles.menu}>
                  <a href="/">
                    <b className={styles.home}>Home</b>
                  </a>
                  <a href="/pages/about">
                    <b className={styles.home}>About us</b>
                  </a>
                  <a href="/pages/profiles">
                    <b className={styles.home}>Members</b>
                  </a>
                  <a href="/pages/news">
                    <b className={styles.home}>News/notice</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.pcMembersContainer}>
          <div
            className={styles.pcMembers}
            style={{ height: profileListHeight > 400 ? 608 + profileListHeight : 1600 }}
          >
            {isLoading ? (
              <ScreenLoader height={1900} />
            ) : (
              <>
                <div className={styles.membersProfileList} ref={profileListRef}>
                  {filteredData.map((item, i) => (
                    <a href={`/${item?.userUrl}`} key={i}>
                      <div className={styles.membersProfileWrapper}>
                        {imageLoading[item.id] && <ScreenLoader height={460} />}
                        <img
                          className={styles.membersProfileImage}
                          alt=""
                          src={item?.representProfileImage?.[0]?.url}
                          onLoad={() => handleImageLoad(item.id)}
                          style={{ display: imageLoading[item.id] ? 'none' : 'block' }}
                        />
                        <div className={styles.membersProfileImageDimed} />
                        <div className={styles.membersProfileNameWrapper}>
                          <div className={styles.membersProfileName}>{item?.userName}</div>
                          <div className={styles.membersProfileEnName}>{item?.userEnName}</div>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </>
            )}
            <div className={styles.dropdown}>
              <div
                className={styles.dropdownHover}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className={styles.div14}>방송진행자 카테고리</div>
                <div className={styles.icoChevronUp20px}>
                  <div className={styles.bg} />
                  <img className={styles.vector66Stroke} alt="" src={imgSrc} />
                </div>
              </div>
            </div>
            {isOpen && (
              <div className="searchBar">
                <div className={styles.searchBar}>
                  <div className={styles.container}>
                    <div className={styles.container1}>
                      <div className={styles.checkboxItemList}>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.announcer}
                            onChange={(e) => handleCheckboxChange(e, 'announcer')}
                          />
                          <div className={styles.mc}>아나운서</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.showHost}
                            onChange={(e) => handleCheckboxChange(e, 'showHost')}
                          />
                          <div className={styles.mc}>쇼호스트</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.eventMC}
                            onChange={(e) => handleCheckboxChange(e, 'eventMC')}
                          />
                          <div className={styles.mc}>행사 MC</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.reporter}
                            onChange={(e) => handleCheckboxChange(e, 'reporter')}
                          />{' '}
                          <div className={styles.mc}>리포터</div>
                        </div>

                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.englishMC}
                            onChange={(e) => handleCheckboxChange(e, 'englishMC')}
                          />
                          <div className={styles.mc}>영어 MC</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.chineseMC}
                            onChange={(e) => handleCheckboxChange(e, 'chineseMC')}
                          />
                          <div className={styles.mc}>중국어 MC</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.japaneseMC}
                            onChange={(e) => handleCheckboxChange(e, 'japaneseMC')}
                          />
                          <div className={styles.mc}>일본어 MC</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.russiaMC}
                            onChange={(e) => handleCheckboxChange(e, 'russiaMC')}
                          />
                          <div className={styles.mc}>러시아 MC</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.container1}>
                      <div className={styles.checkboxItemList}>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.weatherCaster}
                            onChange={(e) => handleCheckboxChange(e, 'weatherCaster')}
                          />{' '}
                          <div className={styles.mc}>기상캐스터</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.sportsCaster}
                            onChange={(e) => handleCheckboxChange(e, 'sportsCaster')}
                          />{' '}
                          <div className={styles.mc}>스포츠캐스터</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.simultaneousInterpreter}
                            onChange={(e) => handleCheckboxChange(e, 'simultaneousInterpreter')}
                          />{' '}
                          <div className={styles.mc}>동시통역사</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.presenter}
                            onChange={(e) => handleCheckboxChange(e, 'presenter')}
                          />{' '}
                          <div className={styles.mc}>프리젠터</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.corporateLecture}
                            onChange={(e) => handleCheckboxChange(e, 'corporateLecture')}
                          />{' '}
                          <div className={styles.mc}>기업강의</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.speechLecture}
                            onChange={(e) => handleCheckboxChange(e, 'speechLecture')}
                          />{' '}
                          <div className={styles.mc}>스피치강의</div>
                        </div>
                        <div className={styles.checkboxItem}>
                          <Form.Check
                            type="checkbox"
                            checked={isChecked.sportsAnnouncer}
                            onChange={(e) => handleCheckboxChange(e, 'sportsAnnouncer')}
                          />{' '}
                          <div className={styles.mc}>스포츠아나운서</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttons1}>
                    <div className={styles.div25}>
                      <div className={styles.copyright2013Container}>
                        <button
                          style={{ color: 'white' }}
                          onClick={() =>
                            setIsChecked({
                              eventMC: true,
                              russiaMC: true,
                              englishMC: true,
                              chineseMC: true,
                              japaneseMC: true,
                              announcer: true,
                              reporter: true,
                              weatherCaster: true,
                              sportsCaster: true,
                              showHost: true,
                              simultaneousInterpreter: true,
                              presenter: true,
                              corporateLecture: true,
                              speechLecture: true,
                              sportsAnnouncer: true,
                            })
                          }
                        >
                          초기화
                        </button>
                      </div>
                    </div>
                    <div className={styles.div27}>
                      <div className={styles.copyright2013Container}>
                        <button onClick={handleFilterData}>적용</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={styles.title} ref={ref}>
              <div className={styles.membersProfile}>
                <div className={styles.profile}>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={isVisible ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 1, ease: 'easeOut' }}
                  >
                    Member’s
                  </motion.p>
                </div>
                <div className={styles.profile}>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={isVisible ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 1, ease: 'easeOut', delay: 0.4 }}
                  >
                    Profile
                  </motion.p>
                </div>
              </div>
              <div className={styles.kfbaContainer}>
                <div className={styles.profile}>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={isVisible ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 1, ease: 'easeOut' }}
                  >
                    <span className={styles.span}>지금 대한민국 방송 행사계를 이끌어 나가고 있는</span>
                  </motion.p>
                </div>
                <div className={styles.profile}>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={isVisible ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 1, ease: 'easeOut', delay: 0.4 }}
                  >
                    <span className={styles.kfba2}>
                      대표 방송진행자 아나운서 <span className={styles.span1}>MC</span>들을 모두 이곳에서 만나보세요.
                    </span>
                  </motion.p>
                </div>
              </div>
            </div>
            <div className={styles.searchBar1}>
              <div className={styles.div29}>
                <InputGroup size="sm">
                  <Form.Control
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="아나운서를 검색해주세요"
                    value={formName}
                    onChange={(e) => setFormName(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </InputGroup>
              </div>
              <button onClick={handleSearchData}>
                <img className={styles.icoChevronUp20px} alt="" src="/profileList/icosearch20px.svg" />
              </button>
            </div>
            <div className={styles.div30}>
              <span>{`총 `}</span>
              <span className={styles.span4}>{filteredData?.length}명</span>
              <span>의 방송진행자</span>
            </div>

            <div className={styles.footer}>
              <div className={styles.menu}>
                <a href="/">
                  <img className={styles.kfbaIcon} alt="" src="/profileList/kfba.svg" />
                </a>
                <div className={styles.menu1}>
                  <a href="/">
                    <div className={styles.aboutUs}>HOME</div>
                  </a>
                  <a href="/pages/about">
                    <div className={styles.aboutUs}>About US</div>
                  </a>
                  <a href="/pages/profiles">
                    <div className={styles.aboutUs}>Members</div>
                  </a>
                  <a href="/pages/news">
                    <div className={styles.aboutUs}>NEWS/NOTICE</div>
                  </a>
                </div>
              </div>
              <div className={styles.info}>
                <div className={styles.copyright2013Container}>
                  <span>{`Copyright © 2013 by `}</span>
                  <b className={styles.kfba}>KFBA</b>
                  <span> All right reserved.</span>
                </div>
                <div className={styles.buttons}>
                  <div className={styles.div12}>개인정보처리방침</div>
                  <div className={styles.div12}>이용약관</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* background */}
        {/* <div className={styles.footerBg} style={{ top: profileListHeight > 400 ? 524 + profileListHeight : 920 }}></div> */}
      </div>
      <MIndex />
    </>
  );
};

export default ProfileListPage;
